import environment from '../helpers/envProvider';

const PAYMENT_ENVIRONMENT = environment("REACT_APP_PAYMENT_ENVIRONMENT") || "production";

const plans = [
  {
    priceMonthly: 0,
    priceYearly: 0,
    name: 'Free',
    description: 'Perfect for experimentation & design ideation.',
    perks: [
      '40 monthly credits.',
      '2 concurrent image generation jobs',
      'Public Generations',
      'Personal use license'
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: '', // Testing
      yearlyPriceId: '', // Testing
    } : {
      monthlyPriceId: '', // Production
      yearlyPriceId: '', // Production
    }),
		credits: 40,
    paidFeatureUsage: {
      vectorize: 2,
      "remove-bg": 2,
    },
  },

  {
    priceMonthly: 24,
    priceYearly: 20,
    name: 'Basic',
    description: 'Perfect for experimentation & design ideation.',
    creditText: '1,000 Credits',
    perks: [
      '1000 Credits / month',
      '4 concurrent image generation jobs',
      'Up to 50 Vectorization jobs',
      'Up to 50 Background Removal jobs',
			'Up to 100 2x Creative Upscaler jobs',
      'Private Generations',
      'Commercial license'
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: 'price_1NLApHHPReHZNX9KYDqGjSGV', // Testing
      yearlyPriceId: 'price_1NLApzHPReHZNX9KoEYkxTYy', // Testing
    } : {
      monthlyPriceId: 'price_1NL3u3HPReHZNX9KMVcDSvCC', // Production
      yearlyPriceId: 'price_1NLAqXHPReHZNX9K4g2cpcfO', // Production
    }),
		credits: 1000,
    paidFeatureUsage: {
      vectorize: 75,
      "remove-bg": 75,
    },
  },

  {
    priceMonthly: 49,
    priceYearly: 40,
    name: 'Pro',
    description: 'Perfect for freelance designers and creators.',
    creditText: '2,500 Credits',
    perks: [
      '2,500 monthly credits.',
      '4 concurrent image generation jobs',
      'Up to 100 Vectorization jobs',
      'Up to 100 Background Removal jobs',
			'Up to 250 2x Creative Upscaler jobs',
      'Private Generations',
      'Commercial license'
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: 'price_1NRd68HPReHZNX9KQDXQDfmh', // Testing
      yearlyPriceId: 'price_1NRd68HPReHZNX9K9qV1xOSp', // Testing
    } : {
      monthlyPriceId: 'price_1NLAswHPReHZNX9KQP9rPqnu', // Production
      yearlyPriceId: 'price_1NLAt8HPReHZNX9KuDwDPrxl', // Production
    }),
		credits: 2500,
    paidFeatureUsage: {
      vectorize: 250,
      "remove-bg": 250,
    },
  },

  {
    priceMonthly: 149,
    priceYearly: 124,
    name: 'Elite',
    description: 'Perfect for businesses & agencies producing at scale.',
    creditText: '10,000 Credits',
    perks: [
      '10,000 monthly credits.',
      '4 concurrent image generation jobs',
      'Up to 250 Vectorization jobs',
      'Up to 250 Background Removal jobs',
			'Up to 1k 2x Creative Upscaler jobs',
      'Private Generations',
      'Commercial license',
    ],
    ...(PAYMENT_ENVIRONMENT === 'test' ? {
      monthlyPriceId: 'price_1NRd7YHPReHZNX9KMQ0A1AOy', // Testing
      yearlyPriceId: 'price_1NRd7YHPReHZNX9KWaeJeNkl', // Testing
    } : {
      monthlyPriceId: 'price_1NLAuRHPReHZNX9KcHVve5ro', // Production
      yearlyPriceId: 'price_1NLAv1HPReHZNX9KzKR7rAYy', // Production
    }),
		credits: 10000,
    paidFeatureUsage: {
      vectorize: 1000,
      "remove-bg": 1000,
    },
  },
]

export default plans;
